function getCountries() {
  return (
    [
      { key: 'GB', text: "United Kingdom", value: "GB", lang: "en"},
      { key: 'US', text: "United States", value: "US", lang: "en"},
      { key: 'AF', text: "Afghanistan", value: "AF", lang: "af"},
      { key: 'AX', text: "Åland Islands", value: "AX", lang: "sv"},
      { key: 'AL', text: "Albania", value: "AL", lang: "sq"},
      { key: 'DZ', text: "Algeria", value: "DZ", lang: "ar"},
      { key: 'AS', text: "American Samoa", value: "AS", lang: "en"},
      { key: 'AD', text: "Andorra", value: "AD", lang: "ca"},
      { key: 'AO', text: "Angola", value: "AO", lang: "pt"},
      { key: 'AI', text: "Anguilla", value: "AI", lang: "en"},
      { key: 'AQ', text: "Antarctica", value: "AQ", lang: "ru"},
      { key: 'AG', text: "Antigua and Barbuda", value: "AG", lang: "en"},
      { key: 'AR', text: "Argentina", value: "AR", lang: "es"},
      { key: 'AM', text: "Armenia", value: "AM", lang: "hy"},
      { key: 'AW', text: "Aruba", value: "AW", lang: "nl"},
      { key: 'AU', text: "Australia", value: "AU", lang: "en"},
      { key: 'AT', text: "Austria", value: "AT", lang: "de"},
      { key: 'AZ', text: "Azerbaijan", value: "AZ", lang: "az"},
      { key: 'BS', text: "Bahamas", value: "BS", lang: "en"},
      { key: 'BH', text: "Bahrain", value: "BH", lang: "ar"},
      { key: 'BD', text: "Bangladesh", value: "BD", lang: "bn"},
      { key: 'BB', text: "Barbados", value: "BB", lang: "en"},
      { key: 'BY', text: "Belarus", value: "BY", lang: "ru"},
      { key: 'BE', text: "Belgium", value: "BE", lang: "nl"},
      { key: 'BQ', text: "Belize", value: "BZ", lang: "en"},
      { key: 'BJ', text: "Benin", value: "BJ", lang: "fr"},
      { key: 'BM', text: "Bermuda", value: "BM", lang: "en"},
      { key: 'BT', text: "Bhutan", value: "BT", lang: "en"},        // Dzongkha but not in list
      { key: 'BO', text: "Bolivia", value: "BO", lang: "es"},
      { key: 'BA', text: "Bosnia and Herzegovina", value: "BA", lang: "hr"},
      { key: 'BW', text: "Botswana", value: "BW", lang: "en"},
      { key: 'BV', text: "Bouvet Island", value: "BV", lang: "en"},        // I can't sure for this
      { key: 'BR', text: "Brazil", value: "BR", lang: "pt"},
      { key: 'IO', text: "British Indian Ocean Territory", value: "IO", lang: "en"},
      { key: 'BN', text: "Brunei Darussalam", value: "BN", lang: "en"},    // Brunei but ...
      { key: 'BG', text: "Bulgaria", value: "BG", lang: "bg"},
      { key: 'BF', text: "Burkina Faso", value: "BF", lang: "fr"},
      { key: 'BI', text: "Burundi", value: "BI", lang: "fr"},
      { key: 'KH', text: "Cambodia", value: "KH", lang: "km"},
      { key: 'CM', text: "Cameroon", value: "CM", lang: "fr"},
      { key: 'CA', text: "Canada", value: "CA", lang: "en"},
      { key: 'CV', text: "Cape Verde", value: "CV", lang: "pt"},
      { key: 'KY', text: "Cayman Islands", value: "KY", lang: "en"},
      { key: 'CF', text: "Central African Republic", value: "CF", lang: "fr"},
      { key: 'TD', text: "Chad", value: "TD", lang: "fr"},
      { key: 'CL', text: "Chile", value: "CL", lang: "es"},
      { key: 'CN', text: "China", value: "CN", lang: "zh"},
      { key: 'CX', text: "Christmas Island", value: "CX", lang: "en"},
      { key: 'CC', text: "Cocos (Keeling) Islands", value: "CC", lang: "ms"},
      { key: 'CO', text: "Colombia", value: "CO", lang: "es"},
      { key: 'KM', text: "Comoros", value: "KM", lang: "fr"},
      { key: 'CG', text: "Congo", value: "CG", lang: "fr"},
      { key: 'CD', text: "Congo, The Democratic Republic of the", value: "CD", lang: "fr"},
      { key: 'CK', text: "Cook Islands", value: "CK", lang: "en"},
      { key: 'CR', text: "Costa Rica", value: "CR", lang: "es"},
      { key: 'CI', text: "Cote D'Ivoire", value: "CI", lang: "fr"},
      { key: 'HR', text: "Croatia", value: "HR", lang: "hr"},
      { key: 'CU', text: "Cuba", value: "CU", lang: "es"},
      { key: 'CY', text: "Cyprus", value: "CY", lang: "el"},
      { key: 'CZ', text: "Czech Republic", value: "CZ", lang: "cs"},
      { key: 'DK', text: "Denmark", value: "DK", lang: "da"},
      { key: 'DJ', text: "Djibouti", value: "DJ", lang: "fr"},
      { key: 'DM', text: "Dominica", value: "DM", lang: "en"},
      { key: 'DO', text: "Dominican Republic", value: "DO", lang: "es"},
      { key: 'EC', text: "Ecuador", value: "EC", lang: "es"},
      { key: 'EG', text: "Egypt", value: "EG", lang: "ar"},
      { key: 'SV', text: "El Salvador", value: "SV", lang: "es"},
      { key: 'GQ', text: "Equatorial Guinea", value: "GQ", lang: "es"},
      { key: 'ER', text: "Eritrea", value: "ER", lang: "en"},      //Tigrinya but not in list....
      { key: 'EE', text: "Estonia", value: "EE", lang: "et"},
      { key: 'ET', text: "Ethiopia", value: "ET", lang: "am"},
      { key: 'FK', text: "Falkland Islands (Malvinas)", value: "FK", lang: "en"},
      { key: 'FO', text: "Faroe Islands", value: "FO", lang: "da"},
      { key: 'FJ', text: "Fiji", value: "FJ", lang: "en"},
      { key: 'FI', text: "Finland", value: "FI", lang: "fi"},
      { key: 'FR', text: "France", value: "FR", lang: "fr"},
      { key: 'GF', text: "French Guiana", value: "GF", lang: "fr"},
      { key: 'PF', text: "French Polynesia", value: "PF", lang: "fr"},
      { key: 'TF', text: "French Southern Territories", value: "TF", lang: "fr"},
      { key: 'GA', text: "Gabon", value: "GA", lang: "fr"},
      { key: 'GM', text: "Gambia", value: "GM", lang: "en"},
      { key: 'GE', text: "Georgia", value: "GE", lang: "ka"},
      { key: 'DE', text: "Germany", value: "DE", lang: "de"},
      { key: 'GH', text: "Ghana", value: "GH", lang: "en"},
      { key: 'GI', text: "Gibraltar", value: "GI", lang: "en"},
      { key: 'GR', text: "Greece", value: "GR", lang: "el"},
      { key: 'GL', text: "Greenland", value: "GL", lang: "en"},
      { key: 'GD', text: "Grenada", value: "GD", lang: "en"},
      { key: 'GP', text: "Guadeloupe", value: "GP", lang: "fr"},
      { key: 'GU', text: "Guam", value: "GU", lang: "en"},
      { key: 'GT', text: "Guatemala", value: "GT", lang: "es"},
      { key: 'GG', text: "Guernsey", value: "GG", lang: "en"},
      { key: 'GN', text: "Guinea", value: "GN", lang: "fr"},
      { key: 'GW', text: "Guinea-Bissau", value: "GW", lang: "pt"},
      { key: 'GY', text: "Guyana", value: "GY", lang: "en"},
      { key: 'HT', text: "Haiti", value: "HT", lang: "es"},
      { key: 'HM', text: "Heard Island and Mcdonald Islands", value: "HM", lang: "en"},
      { key: 'VA', text: "Holy See (Vatican City State)", value: "VA", lang: "it"},
      { key: 'HN', text: "Honduras", value: "HN", lang: "es"},
      { key: 'HK', text: "Hong Kong", value: "HK", lang: "zh"},
      { key: 'HU', text: "Hungary", value: "HU", lang: "cs"},
      { key: 'IS', text: "Iceland", value: "IS", lang: "is"},
      { key: 'IN', text: "India", value: "IN", lang: "bn"},
      { key: 'ID', text: "Indonesia", value: "ID", lang: "id"},
      { key: 'IR', text: "Iran, Islamic Republic Of", value: "IR", lang: "fa"},
      { key: 'IQ', text: "Iraq", value: "IQ", lang: "ku"},
      { key: 'IE', text: "Ireland", value: "IE", lang: "en"},
      { key: 'IM', text: "Isle of Man", value: "IM", lang: "en"},
      { key: 'IL', text: "Israel", value: "IL", lang: "ge"},
      { key: 'IT', text: "Italy", value: "IT", lang: "it"},
      { key: 'JM', text: "Jamaica", value: "JM", lang: "en"},
      { key: 'JP', text: "Japan", value: "JP", lang: "jp"},
      { key: 'JE', text: "Jersey", value: "JE", lang: "french"},
      { key: 'JO', text: "Jordan", value: "JO", lang: "ar"},
      { key: 'KZ', text: "Kazakhstan", value: "KZ", lang: "kk"},
      { key: 'KE', text: "Kenya", value: "KE", lang: "sw"},
      { key: 'KI', text: "Kiribati", value: "KI", lang: "en"},
      { key: 'KP', text: "Korea, Democratic People'S Republic of", value: "KP", lang: "ko"},
      { key: 'KR', text: "Korea, Republic of", value: "KR", lang: "ko"},
      { key: 'KW', text: "Kuwait", value: "KW", lang: "ar"},
      { key: 'KG', text: "Kyrgyzstan", value: "KG",  lang: "ky"},
      { key: 'LA', text: "Lao People'S Democratic Republic", value: "LA",  lang: "en"},
      { key: 'LV', text: "Latvia", value: "LV", lang: "lv"},
      { key: 'LB', text: "Lebanon", value: "LB", lang: "ar"},
      { key: 'LS', text: "Lesotho", value: "LS", lang: "en"},
      { key: 'LR', text: "Liberia", value: "LR", lang: "en"},
      { key: 'LY', text: "Libyan Arab Jamahiriya", value: "LY", lang: "ar"},
      { key: 'LI', text: "Liechtenstein", value: "LI", lang: "de"},
      { key: 'LT', text: "Lithuania", value: "LT", lang: "lt"},
      { key: 'LU', text: "Luxembourg", value: "LU", lang: "fr"},
      { key: 'MO', text: "Macao", value: "MO", lang: "zh"},
      { key: 'MK', text: "Macedonia, The Former Yugoslav Republic of", value: "MK", lang: "mk"},
      { key: 'MG', text: "Madagascar", value: "MG", lang: "fr"},
      { key: 'MW', text: "Malawi", value: "MW", lang: "en"},
      { key: 'MY', text: "Malaysia", value: "MY", lang: "ms"},
      { key: 'MV', text: "Maldives", value: "MV", lang: "dv"},
      { key: 'ML', text: "Mali", value: "ML", lang: "fr"},
      { key: 'MT', text: "Malta", value: "MT", lang: "en"},
      { key: 'MH', text: "Marshall Islands", value: "MH", lang: "en"},
      { key: 'MQ', text: "Martinique", value: "MQ", lang: "fr"},
      { key: 'MR', text: "Mauritania", value: "MR", lang: "ar"},
      { key: 'MU', text: "Mauritius", value: "MU", lang: "fr"},
      { key: 'YT', text: "Mayotte", value: "YT", lang: "fr"},
      { key: 'MX', text: "Mexico", value: "MX", lang: "es"},
      { key: 'FM', text: "Micronesia, Federated States of", value: "FM", lang: "en"},
      { key: 'MD', text: "Moldova, Republic of", value: "MD", lang: "ro"},
      { key: 'MC', text: "Monaco", value: "MC", lang: "fr"},
      { key: 'MN', text: "Mongolia", value: "MN", lang: "mn"},
      { key: 'MS', text: "Montserrat", value: "MS", lang: "es"},
      { key: 'MA', text: "Morocco", value: "MA", lang: "ar"},
      { key: 'MZ', text: "Mozambique", value: "MZ", lang: "pt"},
      { key: 'MM', text: "Myanmar", value: "MM", lang: "en"},  // Burmese but not in list
      { key: 'NA', text: "Namibia", value: "NA", lang: "en"},
      { key: 'NR', text: "Nauru", value: "NR", lang: "en"},
      { key: 'NP', text: "Nepal", value: "NP", lang: "en"},    //Nepali but not in list
      { key: 'NL', text: "Netherlands", value: "NL", lang: "nl"},
      { key: 'AN', text: "Netherlands Antilles", value: "AN" , lang:"nl"},
      { key: 'NC', text: "New Caledonia", value: "NC" , lang: "fr"},
      { key: 'NZ', text: "New Zealand", value: "NZ" , lang:"en"},
      { key: 'NI', text: "Nicaragua", value: "NI" , lang:"es"},
      { key: 'NE', text: "Niger", value: "NE" , lang: "fr"},
      { key: 'NG', text: "Nigeria", value: "NG" , lang:"en"},
      { key: 'NU', text: "Niue", value: "NU" , lang: "en"},
      { key: 'NF', text: "Norfolk Island", value: "NF" , lang:"en"},
      { key: 'MP', text: "Northern Mariana Islands", value: "MP" , lang:"en"},
      { key: 'NO', text: "Norway", value: "NO" , lang:"no"},
      { key: 'OM', text: "Oman", value: "OM" , lang:"ar"},
      { key: 'PK', text: "Pakistan", value: "PK" ,lang:"ur"},
      { key: 'PW', text: "Palau", value: "PW" , lang:"en"},
      { key: 'PS', text: "Palestinian Territory, Occupied", value: "PS" , lang:"ar"},
      { key: 'PA', text: "Panama", value: "PA" , lang: "es"},
      { key: 'PG', text: "Papua New Guinea", value: "PG" , lang: "en"},
      { key: 'PY', text: "Paraguay", value: "PY" , lang:"es"},
      { key: 'PE', text: "Peru", value: "PE" ,lang:"es"},
      { key: 'PH', text: "Philippines", value: "PH" , lang:"en"},
      { key: 'PN', text: "Pitcairn", value: "PN" , lang:"en"},
      { key: 'PL', text: "Poland", value: "PL" , lang: "pl"},
      { key: 'PT', text: "Portugal", value: "PT" , lang:"pt"},
      { key: 'PR', text: "Puerto Rico", value: "PR" , lang:"en"},
      { key: 'QA', text: "Qatar", value: "QA" , lang:"ar"},
      { key: 'RE', text: "Reunion", value: "RE" , lang:"fr"},
      { key: 'RO', text: "Romania", value: "RO" , lang: "ro"},
      { key: 'RU', text: "Russian Federation", value: "RU" , lang: "ru"},
      { key: 'RW', text: "RWANDA", value: "RW" , lang: "en"},
      { key: 'SH', text: "Saint Helena", value: "SH" , lang: "en"},
      { key: 'KN', text: "Saint Kitts and Nevis", value: "KN" , lang: "en"},
      { key: 'LC', text: "Saint Lucia", value: "LC" , lang: "en"},
      { key: 'PM', text: "Saint Pierre and Miquelon", value: "PM" , lang: "fr"},
      { key: 'VC', text: "Saint Vincent and the Grenadines", value: "VC" , lang: "en"},
      { key: 'WS', text: "Samoa", value: "WS" , lang: "en"},
      { key: 'SM', text: "San Marino", value: "SM" , lang: "it"},
      { key: 'ST', text: "Sao Tome and Principe", value: "ST" , lang:"pt"},
      { key: 'SA', text: "Saudi Arabia", value: "SA" , lang:"ar"},
      { key: 'SN', text: "Senegal", value: "SN" , lang:"fr"},
      { key: 'CS', text: "Serbia and Montenegro", value: "CS" , lang:"sr"},
      { key: 'SC', text: "Seychelles", value: "SC" , lang: "fr"},
      { key: 'SL', text: "Sierra Leone", value: "SL" , lang:"em"},
      { key: 'SG', text: "Singapore", value: "SG" , lang:"en"},
      { key: 'SK', text: "Slovakia", value: "SK" , lang: "sk"},
      { key: 'SI', text: "Slovenia", value: "SI", lang: "en"}, //Slovenian but not in list
      { key: 'SB', text: "Solomon Islands", value: "SB" , lang:"en"},
      { key: 'SO', text: "Somalia", value: "SO" , lang:"so"},
      { key: 'ZA', text: "South Africa", value: "ZA" , lang:"en"},
      { key: 'GS', text: "South Georgia and the South Sandwich Islands", value: "GS" , lang:"en"},
      { key: 'ES', text: "Spain", value: "ES" , lang:"es"},
      { key: 'LK', text: "Sri Lanka", value: "LK" , lang:"ta"},
      { key: 'SD', text: "Sudan", value: "SD" , lang: "ar"},
      { key: 'SR', text: "Suriname", value: "SR" , lang: "nl"},
      { key: 'SJ', text: "Svalbard and Jan Mayen", value: "SJ" , lang: "no"},
      { key: 'SZ', text: "Swaziland", value: "SZ" , lang:"en"},
      { key: 'SE', text: "Sweden", value: "SE" , lang:"sv"},
      { key: 'CH', text: "Switzerland", value: "CH" , lang: "de"},
      { key: 'SY', text: "Syrian Arab Republic", value: "SY" , lang: "ar"},
      { key: 'TW', text: "Taiwan, Province of China", value: "TW" , lang:"zh"},
      { key: 'TJ', text: "Tajikistan", value: "TJ", lang: "en"}, /// Tajiki but not in list
      { key: 'TZ', text: "Tanzania, United Republic of", value: "TZ" , lang: "sw"},
      { key: 'TH', text: "Thailand", value: "TH" , lang: "th"},
      { key: 'TL', text: "Timor-Leste", value: "TL" , lang: "pt"},
      { key: 'TG', text: "Togo", value: "TG" , lang: "fr"},
      { key: 'TK', text: "Tokelau", value: "TK" , lang: "en"},
      { key: 'TO', text: "Tonga", value: "TO" , lang: "en"},
      { key: 'TT', text: "Trinidad and Tobago", value: "TT" , lang:"en"},
      { key: 'TN', text: "Tunisia", value: "TN" , lang: "ar"},
      { key: 'TR', text: "Turkey", value: "TR" , lang: "tr"},
      { key: 'TM', text: "Turkmenistan", value: "TM", lang: "en" }, // Turkmen but not in list
      { key: 'TC', text: "Turks and Caicos Islands", value: "TC" , lang: "en"},
      { key: 'TV', text: "Tuvalu", value: "TV" , lang: "en"},
      { key: 'UG', text: "Uganda", value: "UG" , lang: "sw"},
      { key: 'UA', text: "Ukraine", value: "UA" , lang: "uk"},
      { key: 'AE', text: "United Arab Emirates", value: "AE" , lang: "ar"},
      { key: 'UM', text: "United States Minor Outlying Islands", value: "UM" , lang: "en"},
      { key: 'UY', text: "Uruguay", value: "UY" , lang: "es"},
      { key: 'UZ', text: "Uzbekistan", value: "UZ" , lang: "uz"},
      { key: 'VU', text: "Vanuatu", value: "VU" , lang: "en"},
      { key: 'VE', text: "Venezuela", value: "VE" , lang: "es"},
      { key: 'VN', text: "Viet Nam", value: "VN" , lang: "vi"},
      { key: 'VG', text: "Virgin Islands, British", value: "VG" , lang: "en"},
      { key: 'VI', text: "Virgin Islands, U.S.", value: "VI" , lang: "en"},
      { key: 'WF', text: "Wallis and Futuna", value: "WF" , lang: "fr"},
      { key: 'EH', text: "Western Sahara", value: "EH", lang: "ar" },
      { key: 'YE', text: "Yemen", value: "YE", lang: "ar"},
      { key: 'ZM', text: "Zambia", value: "ZM", lang: "en"},
      { key: 'ZW', text: "Zimbabwe", value: "ZW", lang: "en"}
    ])
};

export default getCountries;
